import { wrap } from '@sentry/browser';
import { ApplicationController } from './application_controller.js';

export default class extends ApplicationController {
  static targets = [
    'churchDistributionHelp',
    'deliveryMethod',
    'inPersonDirectShippingHelp',
    'maxRegistrants',
    'registrationFee',
    'registrationFeeInput',
    'paymentMode',
    'paymentMethodOption',
    'registrationFeeCurrency',
    'scholarship',
    'scholarshipLabel',
    'selectedCurriculumWorkbookPrice',
    'summary',
    'summaryScholarshipText',
    'summaryRegistrationFee',
    'summaryRegistrationFeeBlock',
    'summaryWorkbookFee',
    'summaryWorkbookFeeBlock',
    'summaryWorkbookChurchDistributionFeeText',
    'summaryWorkbookDirectShippingFeeText',
    'summaryTotalFee',
    'workbookCost',
    'workbookFee'
  ];

  initialize() {
    if (this.deliveryMethodTarget.value == '') {
      this.deliveryMethodTarget.value = 'in_person'
    };

    if (this.paymentModeTarget.value == '') {
      this.paymentModeTarget.value = 'church_pay'
    };

    if (this.workbookCostTarget.value == '') {
      this.workbookCostTarget.value = 'church'
    };

    this.toggle_max_registrants();
    this.update_payment_method();
    this.update_payment_summary();
    this.toggle_direct_shipping();
  };

  clear_max_registrants() {
    document.getElementById('group_max_registrants').value = "";
  };

  toggle_max_registrants(){
    if (
      event.target == document
      && document.getElementById('group_max_registrants').value != '')
    {
      document.getElementById('disable_max_registrants').checked = false;
      document.getElementById('enable_max_registrants').checked = true;
      this.maxRegistrantsTarget.classList.remove('tw--hidden');
      this.maxRegistrantsTarget.classList.remove('tw--text-disabled');
    } else if (event.target != document && event.target.dataset.value == 'true') {
      this.maxRegistrantsTarget.classList.remove('tw--hidden');
      this.maxRegistrantsTarget.classList.remove('tw--text-disabled');
    } else {
      this.maxRegistrantsTarget.classList.add('tw--hidden');
      this.maxRegistrantsTarget.classList.add('tw--text-disabled');
      this.clear_max_registrants();
    };
  };

  toggle_scholarships() {
    if (this.paymentModeTarget.value == 'church_pay' && this.workbookCostTarget.value == 'church') {
      this.scholarshipTarget.classList.add('tw--text-disabled');
      this.scholarshipLabelTarget.classList = 'tw--text-disabled';
      document.getElementById('group_scholarships_false').checked = true;
      document.getElementById('group_scholarships_true').checked = false;
      document.getElementById('group_scholarships_false').disabled = true;
      document.getElementById('group_scholarships_true').disabled = true;
      document.getElementById('scholarship_tooltip_icon').classList.replace('tw--text-secondary', 'tw--text-disabled');
    } else {
      this.scholarshipTarget.classList.remove('tw--text-disabled');
      this.scholarshipLabelTarget.classList = 'tw--text-secondary';
      document.getElementById('group_scholarships_false').disabled = false;
      document.getElementById('group_scholarships_true').disabled = false;
      document.getElementById('scholarship_tooltip_icon').classList.replace('tw--text-disabled', 'tw--text-secondary');
    };

    // Toggle on summary
    if (document.getElementById('group_scholarships_true').checked == true) {
      this.summaryScholarshipTextTarget.classList.remove('tw--hidden');
    } else {
      this.summaryScholarshipTextTarget.classList.add('tw--hidden');
    };
  };

  toggle_registration_fee() {
    var location_id = document.getElementById('group_location_id').value.toString();
    var country = document.querySelectorAll("[data-location-id='" + location_id + "']")[0].dataset.country;

    this.registrationFeeInputTarget.value = Number(this.registrationFeeInputTarget.value).toFixed(2);

    if (this.paymentModeTarget.value == 'church_pay') {
      if (this.workbookCostTarget.value == 'church') {
        this.registrationFeeTarget.classList.add('tw--text-disabled')
        this.churchDistributionHelpTarget.classList.add('tw--hidden');
        this.inPersonDirectShippingHelpTarget.classList.add('tw--hidden');
        document.getElementById('reg_fee_tooltip_icon').classList.replace('tw--text-secondary', 'tw--text-disabled');
        document.getElementById('group_registration_fee').min = 0;
      } else if (this.workbookCostTarget.value == 'participant') {
        this.registrationFeeTarget.classList.remove('tw--text-disabled')
        this.inPersonDirectShippingHelpTarget.classList.add('tw--hidden');
        document.getElementById('reg_fee_tooltip_icon').classList.replace('tw--text-disabled', 'tw--text-secondary');
        if (country == 'US' || country == 'CA') {
          this.churchDistributionHelpTarget.classList.remove('tw--hidden');
        } else {
          this.churchDistributionHelpTarget.classList.add('tw--hidden');
        }
        document.getElementById('group_registration_fee').min = 1;
      }
    } else if (this.paymentModeTarget.value == 'participant_pay') {
      if (this.deliveryMethodTarget.value == 'in_person') {
        this.registrationFeeTarget.classList.remove('tw--text-disabled')
        this.churchDistributionHelpTarget.classList.add('tw--hidden');
        this.inPersonDirectShippingHelpTarget.classList.remove('tw--hidden');
        document.getElementById('reg_fee_tooltip_icon').classList.replace('tw--text-disabled', 'tw--text-secondary');
      } else {
        this.registrationFeeTarget.classList.add('tw--text-disabled')
        this.churchDistributionHelpTarget.classList.add('tw--hidden');
        this.inPersonDirectShippingHelpTarget.classList.add('tw--hidden');
        document.getElementById('reg_fee_tooltip_icon').classList.replace('tw--text-secondary', 'tw--text-disabled');
      };
      document.getElementById('group_registration_fee').min = 0;
    };
  };

  update_payment_method() {
    var location_id = document.getElementById('group_location_id').value.toString();
    var country = document.querySelectorAll("[data-location-id='" + location_id + "']")[0].dataset.country;

    var available_methods = this.paymentMethodOptionTargets;
    for (var i=0; i < available_methods.length; i++) {
      var pmo = available_methods[i];

      var payment_mode = '';
      if (event.target == document) {
        payment_mode = this.paymentModeTarget.value;
      } else {
        payment_mode = event.target.dataset.payment_mode;
      };

      var workbook_cost = '';
      if (event.target == document) {
        workbook_cost = this.workbookCostTarget.value;
      } else {
        workbook_cost = event.target.dataset.workbook_cost;
      };

      if (payment_mode == 'participant_pay' && workbook_cost == 'participant' && country != 'US' && country != 'CA') {
        // do nothing since direct shipping is diabled in this scenario
        return;
      } else {
        if (
          pmo.dataset.payment_mode == payment_mode
          && pmo.dataset.workbook_cost == workbook_cost
        ) {
          pmo.classList.add('tw--bg-tertiary', 'tw--outline', 'tw--outline-3', 'tw--outline-active', 'tw--border-transparent');
          pmo.classList.remove('tw--border-primary');
          this.paymentModeTarget.value = payment_mode;
          this.workbookCostTarget.value = workbook_cost;
        } else {
          pmo.classList.remove('tw--bg-tertiary', 'tw--outline', 'tw--outline-3', 'tw--outline-active', 'tw--border-transparent');
          pmo.classList.add('tw--border-primary');
        };
      }
    };
    this.toggle_registration_fee();
    this.toggle_scholarships();
    this.update_payment_summary();
  };

  update_payment_summary() {
    let threshold = parseFloat( document.getElementById('default_workbook_cost').innerHTML );
    //let threshold = (type == 'group') ? 20 : 7;

    var location_id = document.getElementById('group_location_id').value.toString();
    var country = document.querySelectorAll("[data-location-id='" + location_id + "']")[0].dataset.country;
    var registration_fee = 0;
    if (this.registrationFeeInputTarget.value != ''){
      registration_fee = Number(this.registrationFeeInputTarget.value);
    };

    var workbook_fee = Number(this.selectedCurriculumWorkbookPriceTarget.value);
    var total_fee = Number(registration_fee) + Number(workbook_fee);
    if (this.paymentModeTarget.value == 'church_pay' && this.workbookCostTarget.value == 'church') {
      this.summaryTarget.classList.add('tw--hidden')
    } else if (this.paymentModeTarget.value == 'participant_pay' && this.workbookCostTarget.value == 'participant') {
      this.summaryTarget.classList.remove('tw--hidden');
      workbook_fee = threshold;
      total_fee = Number(registration_fee) + Number(workbook_fee);
      this.summaryWorkbookChurchDistributionFeeTextTarget.classList.add('tw--hidden');
      this.summaryWorkbookDirectShippingFeeTextTarget.classList.remove('tw--hidden');
      if (this.deliveryMethodTarget.value == 'in_person') {
        if (registration_fee == 0) {
          this.summaryWorkbookFeeBlockTarget.classList.remove('tw--hidden');
          this.summaryRegistrationFeeBlockTarget.classList.add('tw--hidden');
          this.summaryWorkbookFeeTarget.value = Number(total_fee);
        } else {
          this.summaryWorkbookFeeBlockTarget.classList.remove('tw--hidden');
          this.summaryRegistrationFeeBlockTarget.classList.remove('tw--hidden');
          this.summaryWorkbookFeeTarget.value = Number(workbook_fee);
          this.summaryRegistrationFeeTarget.value = Number(registration_fee);
        };
      } else {
        this.summaryWorkbookFeeBlockTarget.classList.remove('tw--hidden');
        this.summaryRegistrationFeeBlockTarget.classList.add('tw--hidden');
        this.summaryWorkbookFeeTarget.value = Number(total_fee);
      };
    } else {
      this.summaryTarget.classList.remove('tw--hidden');
      this.summaryWorkbookChurchDistributionFeeTextTarget.classList.remove('tw--hidden');
      this.summaryWorkbookDirectShippingFeeTextTarget.classList.add('tw--hidden');
      workbook_fee = 0;
      total_fee = Number(registration_fee) + Number(workbook_fee);
      if (total_fee <= threshold || ( country != 'US' && country != 'CA') ) {
        this.summaryWorkbookFeeBlockTarget.classList.remove('tw--hidden'); 
        this.summaryRegistrationFeeBlockTarget.classList.add('tw--hidden');
        this.summaryWorkbookFeeTarget.value = Number(registration_fee);
      } else {
        this.summaryWorkbookFeeBlockTarget.classList.remove('tw--hidden'); 
        this.summaryRegistrationFeeBlockTarget.classList.remove('tw--hidden');
        this.summaryWorkbookFeeTarget.value = threshold;
        this.summaryRegistrationFeeTarget.value = Number(total_fee) - threshold;
      };
    };

    this.summaryRegistrationFeeTarget.innerHTML = Number(this.summaryRegistrationFeeTarget.value).toFixed(2);
    this.summaryWorkbookFeeTarget.innerHTML = Number(this.summaryWorkbookFeeTarget.value).toFixed(2);
    this.summaryTotalFeeTarget.innerHTML = Number(total_fee).toFixed(2);
  };

  clear_registration_fee() {
    this.registrationFeeInputTarget.value = 0;
  };

  toggle_direct_shipping() {
    var location_id = document.getElementById('group_location_id').value.toString();
    var country = document.querySelectorAll("[data-location-id='" + location_id + "']")[0].dataset.country;

    if (country == 'US' || country == 'CA') {
      this.paymentMethodOptionTargets[2].classList.remove('tw--bg-secondary', 'tw--text-disabled', 'tw--border-disabled');
      this.paymentMethodOptionTargets[2].classList.add('tw--cursor-pointer');
    } else {
      this.paymentMethodOptionTargets[2].classList.add('tw--bg-secondary', 'tw--text-disabled', 'tw--border-disabled');
      this.paymentMethodOptionTargets[2].classList.remove('tw--cursor-pointer');

      if (this.paymentModeTarget.value == 'participant_pay' && this.workbookCostTarget.value == 'participant') {
        // remove the active class from the direct shipping option
        this.paymentMethodOptionTargets[2].classList.remove('tw--bg-tertiary', 'tw--outline', 'tw--outline-3', 'tw--outline-active', 'tw--border-transparent');

        // add the active class to the church distribution option and set the payment mode and workbook cost
        this.paymentMethodOptionTargets[0].classList.add('tw--bg-tertiary', 'tw--outline', 'tw--outline-3', 'tw--outline-active', 'tw--border-transparent');
        this.paymentMethodOptionTargets[0].classList.remove('tw--border-primary');
        this.paymentModeTarget.value = 'church_pay';
        this.workbookCostTarget.value = 'church';
        this.clear_registration_fee();
        this.toggle_registration_fee();
        this.toggle_scholarships();
        this.update_payment_summary();
      };
    };
  };

  update_currency_symbol() {
    var location_id = document.getElementById('group_location_id').value.toString();
    var currency = document.querySelectorAll("[data-location-id='" + location_id + "']")[0].dataset.currency;
    this.registrationFeeCurrencyTargets.forEach((element) => {
      element.innerHTML = currency;
    })
  }
};
