
import { ApplicationController } from './application_controller.js';

export default class extends ApplicationController {
  static targets = [
    'row',
    'filter',
    'noPast',
    'noUpcoming',
    'pastTable',
    'upcomingTable'
  ];

  connect() {
    this.filter_table()
  }

  filter_table() {
    // Reset Table CSS
    this.noUpcomingTarget.classList.add('tw--hidden')
    this.noPastTarget.classList.add('tw--hidden')
    this.upcomingTableTarget.classList.remove('tw--hidden')
    this.pastTableTarget.classList.remove('tw--hidden')
    document.getElementById('upcoming-rows').classList.add('max-md:tw--divide-y')
    document.getElementById('past-rows').classList.add('max-md:tw--divide-y')
    
    if (owned_curriculums_count > 1) {
      // Set URL Parameter
      this.set_url_param()
      // Hide/Show Rows
      this.rowTargets.forEach(row => {
        if (eventType == 'all' || row.dataset.eventType == eventType) {
          row.classList.remove('!tw--hidden')
        } else {
          row.classList.add('!tw--hidden')
        }
      });
    }

    // Determine # Rows
    let count_upcoming_sth = this.upcomingTableTarget.querySelectorAll("[data-event-type='surviving_holidays']:not(.tw--collapse):not(.tw--hidden)").length
    let count_upcoming_los = this.upcomingTableTarget.querySelectorAll("[data-event-type='loss_of_spouse']:not(.tw--collapse):not(.tw--hidden)").length
    let count_past_sth = this.pastTableTarget.querySelectorAll("[data-event-type='surviving_holidays']:not(.tw--collapse):not(.tw--hidden)").length
    let count_past_los = this.pastTableTarget.querySelectorAll("[data-event-type='loss_of_spouse']:not(.tw--collapse):not(.tw--hidden)").length

    // Hide No Results Messages
    if ((eventType == 'loss_of_spouse' && count_upcoming_los == 0) || (eventType == 'surviving_holidays' && count_upcoming_sth == 0) || (count_upcoming_los == 0 && count_upcoming_sth == 0)) {
      this.upcomingTableTarget.classList.add('tw--hidden')
      this.noUpcomingTarget.classList.remove('tw--hidden')
    }

    if ((eventType == 'loss_of_spouse' && count_past_los == 0) || (eventType == 'surviving_holidays' && count_past_sth == 0) || (count_past_los == 0 && count_past_sth == 0)) {
      this.pastTableTarget.classList.add('tw--hidden')
      this.noPastTarget.classList.remove('tw--hidden')
    }

    // Hide Divider if there is only one result
    if ((eventType == 'loss_of_spouse' && count_past_los == 1 ) || (eventType == 'surviving_holidays' && count_past_sth == 1 )) {
      document.getElementById('past-rows').classList.remove('max-md:tw--divide-y')
    }

    if ((eventType == 'loss_of_spouse' && count_upcoming_los == 1 ) || (eventType == 'surviving_holidays' && count_upcoming_sth == 1 )) {
      document.getElementById('upcoming-rows').classList.remove('max-md:tw--divide-y')
    }
  }

  set_url_param() {
    eventType = this.filterTarget.value.toString()
    url.searchParams.set("eventType", eventType);
    history.pushState({}, "", url);
  }
};
